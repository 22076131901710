<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Workflow List
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-col
          class="content-header-right mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <b-button
            variant="success"
            :to="{ name: 'operation-workflows-create' }"
            class="mobile_create_button"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle d-md-block d-none">Create New Workflow</span>
            <span class="align-middle d-md-none d-block">New</span>
          </b-button>
        </b-col>
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="(tab, index) in pageTabs"
            :key="index"
            @click="changeRoute(tab.param)"
          >
            {{ tab.title }} ({{ tab.count }})
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          pills
          class="booking__content"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <!-- Tab: Account -->
          <b-tab
            :active="$route.params.type == 'active'"
            lazy
            @click="changeRoute('active')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Active <span class="rounded-number">{{ activeCount }}</span></span>
            </template>
            <active-work-flow
              class="mt-2 pt-75"
              :category-options="categoryOptions"
              @emitCountWorkFlow="countWorkFlows"
            />
          </b-tab>

          <!-- Tab: Information -->
          <b-tab
            :active="$route.params.type == 'archived'"
            lazy
            @click="changeRoute('archived')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Archived <span class="rounded-number">{{ archivedCount }}</span></span>
            </template>
            <archived-work-flow
              class="mt-2 pt-75"
              :category-options="categoryOptions"
              @emitCountWorkFlow="countWorkFlows"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'all-work-flows'"
            lazy
            @click="changeRoute('all-work-flows')"
          >
            <template #title>
              <span class="d-none d-sm-inline">All Workflows <span class="rounded-number">{{ allCount }}</span></span>
            </template>
            <all-work-flow
              class="mt-2 pt-75"
              :category-options="categoryOptions"
              @emitCountWorkFlow="countWorkFlows"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BTab, BTabs, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AllWorkFlow from './all-work-flows/AllWorkFlow.vue'
import ActiveWorkFlow from './active-work-flows/ActiveWorkFlow.vue'
import ArchivedWorkFlow from './archived-work-flows/ArchivedWorkFlow.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,

    AllWorkFlow,
    ActiveWorkFlow,
    ArchivedWorkFlow,
  },
  data() {
    return {
      allCount: 0,
      activeCount: 0,
      archivedCount: 0,
      categoryOptions: [],
      currentTabTitle: '',
      pageTabs: [
        {
          title: 'Active',
          param: 'active',
          count: 0,
        },
        {
          title: 'Archived',
          param: 'archived',
          count: 0,
        },
        {
          title: 'All Workflows',
          param: 'all-work-flows',
          count: 0,
        },
      ],
    }
  },
  watch: {
    $route() {
      const param = this.$route.params.type
      const currentTab = this.pageTabs.find(o => o.param === param)
      if (currentTab) {
        this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
      }
    },
  },
  beforeMount() {
    this.countWorkFlows()
  },

  methods: {
    changeRoute(param) {
      this.$router.replace({
        params: {
          type: param,
        },
        query: {},
      })
    },
    countWorkFlows() {
      this.$http.get('operation/work-flows/counts')
        .then(response => {
          this.allCount = response.data.allCount
          this.activeCount = response.data.activeCount
          this.archivedCount = response.data.archivedCount
          this.categoryOptions = response.data.categoryOptions

          this.pageTabs.forEach(element => {
            if (element.title === 'All Workflows') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.allCount
            } else if (element.title === 'Archived') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.archivedCount
            } else if (element.title === 'Active') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.activeCount
            }
          })

          const param = this.$route.params.type

          const currentTab = this.pageTabs.find(o => o.param === param)
          if (currentTab) {
            this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
