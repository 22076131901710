import Vue from 'vue'
import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useWorkFlowList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refArchivedWorkFlowsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: 'workflow name',
      sortable: true,
    },
    {
      key: 'description',
      label: 'description',
      sortable: true,
    },
    {
      key: 'customerBookable',
      label: 'show in portal',
      sortable: true,
    },
    {
      key: 'category.name',
      label: 'category',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'created date',
      sortable: true,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalWorkFlows = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'createdAt')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const statusFilter = ref([])
  if (Array.isArray(route.query.status)) {
    statusFilter.value = route.query.status
  } else if (route.query.status) {
    statusFilter.value = [route.query.status]
  }
  const categoryFilter = ref([])
  if (Array.isArray(route.query.category)) {
    categoryFilter.value = route.query.category
  } else if (route.query.category) {
    categoryFilter.value = [route.query.category]
  }
  const toDeletedID = ref(null)
  const type = ref('archived')
  const workFlowData = ref([])
  const createdAtFilter = ref(route.query.createdAt || '')
  let initialLoad = true
  const dataDuplicated = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refArchivedWorkFlowsTable.value ? refArchivedWorkFlowsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalWorkFlows.value,
    }
  })

  const refetchData = () => {
    refArchivedWorkFlowsTable.value.refresh()
  }

  watch([searchQuery, statusFilter, categoryFilter, createdAtFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchWorkFlows = (ctx, callback) => {
    store
      .dispatch('app-archived-work-flows/fetchWorkFlows', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        category: categoryFilter.value,
        createdAt: createdAtFilter.value,
        type: type.value,
      },
      { root: true })
      .then(response => {
        const { workFlows, total } = response.data
        workFlowData.value = workFlows
        callback(workFlows)
        totalWorkFlows.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,
              category: categoryFilter.value,
              createdAt: createdAtFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
        dataDuplicated.value = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteWorkFlow = () => {
    store
      .dispatch('app-archived-work-flows/deleteWorkFlow', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const duplicateWorkFlow = () => {
    store
      .dispatch('app-archived-work-flows/duplicateWorkFlow', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        currentPage.value = 1
        dataDuplicated.value = true
        Vue.swal({
          title: 'Successfully Duplicated!',
          html: `${response.data.prevName} workflow has been successfully duplicated. You may find it in the workflow listings.`,
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/save.png'),
          reverseButtons: true,
          showCancelButton: false,
          confirmButtonText: 'Okay, Got It.',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveWorkFlowStatusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'archived') return 'secondary'
    return 'primary'
  }

  return {
    fetchWorkFlows,
    tableColumns,
    perPage,
    currentPage,
    totalWorkFlows,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refArchivedWorkFlowsTable,

    resolveWorkFlowStatusVariant,
    refetchData,

    toDeletedID,
    deleteWorkFlow,
    duplicateWorkFlow,
    workFlowData,
    dataDuplicated,

    // Extra Filters
    statusFilter,
    categoryFilter,
    createdAtFilter,
  }
}
